export class Images
{
    public static readonly ErgalioLogoWhite = require('./ergalio_logo_white2.svg');
    public static readonly ErgalioLogoCyan = require('./ergalio_logo_cyan2.svg');
    public static readonly StockConnectorPreview = require('./stock_connector_preview.png');
    public static readonly WorkspacePreview = require('./workspace_preview.png');
    public static readonly WorkspaceTopologyView = require('./workspace_topology_view.png');
    public static readonly SwimmerDemoView = require('./swimmer_demo_view.png');
    public static readonly HamburgerIcon = require('./hamburger_icon.svg');
    public static readonly CompanyAddress = require('./company_address_2.png');
    public static readonly OfficeInsideView = require('./office_inside_view.jpg');
    public static readonly OfficeOutsideView = require('./office_outside_view.jpg');
    public static readonly OfficeOuting = require('./office_outing.jpg');
    public static readonly OfficeOuting2 = require('./office_outing_2.jpg');
    public static readonly OfficeOuting2v2 = require('./office_outing_2v2.jpg');
    public static readonly OfficeConfRoom = require('./office_conf_room.jpg');
    public static readonly OfficeByEntrance = require('./office_by_entrance.jpg');
    public static readonly TechArchOverview = require('./tech_arch_overview.svg');
    public static readonly TechPlatformCore = require('./tech_platform_core.svg');
    public static readonly CompanyLocation = require('./company_location.png');
    public static readonly ITServices__Reports = require('./it_services__reports.png');
    public static readonly ITServices__Home = require('./it_services__home.jpg');
    public static readonly ITServices__Home2 = require('./it_services__home2.jpg');
    public static readonly Founder = require('./founder.jpg');
    public static readonly M_Ilyin = require('./m_ilyin.jpg');
}