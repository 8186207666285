import * as React from 'react';
import * as classnames from 'classnames/bind';
import {Text, TextSize, TextWeight} from './Text';
import {Images} from '../images/Images';
import {FooterBlock} from './FooterBlock';
import {VBox, VBoxHAlign} from './VBox';
import {SiteButton} from './SiteButton';
import {ContentBlock} from './ContentBlock';
import {HBox, HBoxVAlign} from './HBox';
import {VSpacer} from './VSpacer';

const styles = require('./PageFooter.less');
const cx = classnames.bind(styles) as any;

export class MenuItem
{
    constructor(public title:string,
                public link:string)
    {
        this.title = title;
        this.link = link;
    }
}

export interface PageFooterProps
{
}

export class PageFooter extends React.Component<PageFooterProps, any>
{
    render()
    {
        return  <div className={cx('pageFooter')}>
                    <div className={'container'} style={{paddingTop:'40px'}}>
                        <div className={'row'}>
                            {/*<div className={'col-lg-2'}>*/}
                            {/*    <FooterBlock title={'Home'} footerItems={*/}
                            {/*        [*/}
                            {/*            new MenuItem('Event streams', '/'),*/}
                            {/*            new MenuItem('What is fast data?', '/'),*/}
                            {/*        ]}/>*/}
                            {/*</div>*/}
                            <div className={'col-lg-3'}>
                                <FooterBlock title={'IT Services'} footerItems={
                                    [
                                        new MenuItem('Software Development', '/services'),
                                        new MenuItem('Software Testing', '/services'),
                                        new MenuItem('Outsourced Developers', '/services'),
                                        new MenuItem('Outsourced QA', '/services'),
                                        new MenuItem('Project Management', '/services'),
                                        new MenuItem('Product Development', '/services'),
                                        // new MenuItem('Visual Builder', '/technology'),
                                    ]}/>
                            </div>
                            <div className={'col-lg-3'}>
                                <FooterBlock title={'Industries'} footerItems={
                                    [
                                        new MenuItem('Internet of Things', '/services'),
                                        new MenuItem('Financial Services', '/services'),
                                        new MenuItem('Advertising Technology', '/services'),
                                        new MenuItem('Real-time ETL', '/services'),
                                        new MenuItem('Web Crawling', '/services'),
                                    ]}/>
                            </div>
                            <div className={'col-lg-3'} style={{marginBottom:'10px'}}>
                                <FooterBlock title={'Company'} footerItems={
                                    [
                                        new MenuItem('Mission', '/company'),
                                        new MenuItem('Philosophy', '/company'),
                                        new MenuItem('Team', '/company'),
                                        new MenuItem('Our Culture', '/company'),
                                        new MenuItem('Get In Touch', '/company'),
                                    ]}/>
                            </div>
                            {/*<div className={'col-lg-2'} style={{marginBottom:'10px'}}>*/}
                            {/*</div>*/}
                            <div className={'col-lg-3'}>
                                <img src={Images.CompanyLocation} width={212}/>

                                <VSpacer size={30}/>
                                <VBox halign={VBoxHAlign.flexStart}>
                                    <Text color={'white'} size={TextSize.h4} weight={TextWeight.w300} padding={'0px 0px 5px 0px'} spacing={1.5}>
                                        hello@ergalio.net
                                    </Text>
                                    <SiteButton text={'Get In Touch'} link={'mailto:hello@ergalio.net'}/>
                                </VBox>

                                <VSpacer size={30}/>
                            </div>

                        </div>
                    </div>
            </div>;
    }
}











